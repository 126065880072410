
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import UserAccess from "@/model/User/UserAccess";

@Component({
  name: "DashboardIbotech",
  computed: {
    ...mapGetters(["getUserAccess", "getCurrentUser", "isIbotechSignMeasurementUser", "isIbotechSignMeasurementAdmin"])
  }
})
export default class DashboardIbotech extends Vue {
  readonly getUserAccess!: UserAccess;
  readonly getCurrentUser!: any;
  private readonly isIbotechSignMeasurementUser!: boolean;
  private readonly isIbotechSignMeasurementAdmin!: boolean;

  created() {
    console.log("GETUSERACESS", this.getUserAccess);
    console.log("getCurrentUser", this.getCurrentUser);

    if (!this.getUserAccess.AccessIbotech.VIEW.dashboard) {
      this.$router.push({ name: "NotFound" });
    }
  }
}
